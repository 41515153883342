/* eslint-disable no-process-env */

import { icon } from 'leaflet'

export const config = {
	// URL hosting the backend.
	apiUrl: process.env.VUE_APP_API_URL || 'https://backend.production.cyclokube.agifly.cloud',

	subscriptionRedirectUrl: `${process.env.VUE_APP_ROOT_URL}/subscription?step=3`,
	profileRedirectUrl: `${process.env.VUE_APP_ROOT_URL}/profile`,
	temporaryAccessRedirectUrl: `${process.env.VUE_APP_ROOT_URL}/TemporaryAccess`,

	// Log level for console output
	consoleLogLevel: process.env.VUE_APP_CONSOLE_LOG_LEVEL || 'silly',

	stripePublicKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
	molliePublicKey: process.env.VUE_APP_MOLLIE_PUBLIC_KEY,

	googleApiKey: process.env.VUE_APP_GOOGLE_API_KEY,
	jawgApiKey: process.env.Vue_APP_JAWG_API_KEY,
	orsApiKey: process.env.ORS_API_KEY,
	googleTagManagerId: process.env.VUE_APP_GTM_ID,
	gtmEnabled: process.env.VUE_APP_GTM_ENABLED,
}

export const environment = process.env.NODE_ENV
export const APP_ENV = process.env.VUE_APP_ENV
export const APP_ENV_SESSION = false

export const inputDebounceTime = 500

export const MAX_POI = 2

export const languages = [
	{ key: 'nl', value: 'Nederlands' },
	{ key: 'fr', value: 'Français' },
	{ key: 'en', value: 'English' },
]

export const SignupLanguages = [
	{ key: 'nl', value: 'Nederlands', icon: 'icon_flag_nl.svg' },
	{ key: 'fr', value: 'Français', icon: 'icon_flag_fr.svg' },
	{ key: 'en', value: 'English', icon: 'icon_flag_en.svg' },
]

export const UILanguages = [
	{ key: 'nl', value: 'nl' },
	{ key: 'fr', value: 'fr' },
	{ key: 'en', value: 'en' },
]

export const addressTypes = ['home', 'poi']

export const parkingDistanceFilters = {
	lower: 200,
	upper: 500,
}

// TODO: load these from server
export const parkingTypes = [
	'Box',
	'BigParking',
	'Big100',
	'NeighborhoodLocker',
	'NeighborhoodLockerCargo',
	'P+R',
	'PrivateParkings',
]

export const invoiceItemTypes = {
	SUBSCRIPTION: 1,
	DEPOSIT: 2,
	NEW_KEY: 3,
	REGULARISATION: 4,
	MISC: 5,
}

export const ignoredParkingTypes = ['Big100']
export const bigParkingTypes = ['Big100', 'BigParking']

export const keyTypes = {
	default: 0,
	mobib: 1,
	airkey: 2,
	bepark: 3,
}

export const USER_STATES = {
	pend: 0,
	act: 1,
	susp: 3, // not yet used
	imp: 4,
}

export const SUBSCRIPTION_STATES = {
	new: 1,
	pending: 2,
	active: 3,
	cancelled: 5,
	cancelAtEnd: 4,
}

export const PROPOSAL_STATES = {
	active: 1, // pending state
	accepted: 2,
	rejected: 3,
	expired: 4,
	cancelled: 5,
}

export const REQUEST_STATES = {
	active: 1,
	completed: 2,
	deleted: 4,
	cancelled: 5,
}

export const DEFAULT_PRICES = {
	deposit: 20,
	boxSubscription: 60,
}

export const DEPOSIT_STATES = {
	pending: 2, // pending
	active: 3, // paid
	cancelled: 4, // reimbursed
	closed: 5, // income
}

export const REPORT_FILTER_GROUPS = {
	period: 'Period',
	product: 'Product',
	parking: 'Parking',
	subscription: 'Subscription',
}

export const INVOICE_ITEM_STATES = {
	new: 1,
	pending: 2, // sync with stripe in progress. Should be a temp status. If an item stays in this status we have to manually investigate and fix
	active: 3, // invoice_item successfully synched with stripe (but doesn't mean that it's paid. payment may have fail
}

export const REFUND_STATES = {
	new: 1,
	pending: 2, // sync with stripe in progress. should be a temp status. if an item stays in this status we have to manually investigate and fix
	created: 3, // refund creation succeeded in stripe, and submitted to the user's bank. but it's not yet actually refunded. it may take up to 1 month to be processed by the bank and can then either be rejected of paid
	failed: 4, // refund creation failed in stripe (stripe directely returned an error, no refund item was created in stripe). (i guess that we can get this kind of error when the payment was paid via bancontact more then 6 months ago.
	rejected: 5, // the refund was rejected by the user's bank
	paid: 6, // The refund was actually refunded by the user's bank
}

// Keep this in sync with the backend 'STATUSES' constant in src/constants/temporary-access-constants.js
export const TEMPORARY_ACCESS_STATUSES = {
	CANCELED: 1, // Was maybe canceled because the user did not scan the start QC-code on time -> No wow log entry was returned after the 5min of life-time of the QR-code. The TemporaryAccess is now active: false and the user is now able to initiate a new temporaryAccess session
	START_CODE_GENERATED: 2, // The QR-code has been generated and the user is now able to enter the parking and start the session
	STARTED: 3, // The user DID scan the QR-code -> Wow do return a log entry for an opened-parking-gate with that code. We'll calculate the price starting from this time. The user is still inside the parking, he now need to scan the qr-code again on an inside gate scanner
	STARTED_AND_WENT_OUT: 4, // the user did scan an inside gate scanner. All wow autorisation are now cancelled
	END_CODE_GENERATED: 5, // The QR-code (to get the bike out) has been generated adn the user is now able to enter the parking
	ENTERED_READY_TO_END: 6, // The user DID scan the QR_code to enter the parking, he is still inside the parking, he now needs to scan the qr-code again on an inside gate scanner
	ENDED: 7, // The user DID scan the QR-code on an inside gate scanner -> Wow do return a log entry for an opened-parking-gate with that code. We'll calculate the price until this time
	INVOICED: 8, // Invoice has been successfully created into Stripe and sent to the User
	PAID: 9, // The invoice has been paid. We can not mark this TemporaryAccess as active:false and the user will be able to initiate new temporaryAccess sessions.
}

// in milisec
// 5min => 300000
export const TEMPORARY_ACCESS_QR_CODE_GET_IN_DURATION = 300000
export const TEMPORARY_ACCESS_QR_CODE_RETRY_DURATION = 300000

// 1h = 3600000
export const TEMPORARY_ACCESS_QR_CODE_GET_OUT_DURATION = 3600000

// // You can only retry until 5min after your last scan attempt
// 5min = 30000ms
export const DELAY_TO_RETRY = 300000

// Time durations:
export const NUMBER_OF_MILISEC_IN_A_SEC = 1000
export const NUMBER_OF_SEC_IN_A_MIN = 60
export const NUMBER_OF_MILISEC_IN_A_MIN = NUMBER_OF_MILISEC_IN_A_SEC * NUMBER_OF_SEC_IN_A_MIN
export const NUMBER_OF_MIN_IN_AN_HOUR = 60
export const NUMBER_OF_HOUR_IN_A_DAY = 24
export const NUMBER_OF_MILISEC_IN_AN_HOUR = NUMBER_OF_MILISEC_IN_A_MIN * NUMBER_OF_MIN_IN_AN_HOUR
export const NUMBER_OF_MILISEC_IN_A_DAY = NUMBER_OF_MILISEC_IN_AN_HOUR * NUMBER_OF_HOUR_IN_A_DAY

// Query limits
export const DEFAULT_QUERY_LIMIT = 1500
export const REQUEST_QUERY_LIMIT = 3000
export const PARKING_QUERY_LIMIT = 200

export const ALLOW_PAYMENT_LINKS_IN_PROD = false

import graphqlClient from '@/api/db'
import gql from 'graphql-tag'
import users from './users'
import attribution from './attribution'
import parkings from './parkings'
import municipality from './municipality'
import coupons from './coupon'
import sessions from './session'
import { subscriptionAdminProps } from '../subscription'
import { findIndex, sortBy, reverse, map, isEmpty, omit, flatten, find } from 'lodash/fp' // , find, flatten
import exp from './exports'
import reports from './reports'
import Vue from 'vue'
import i18n from '@/i18nVeeValidate'
import { logger } from '@/logger'

// Initial state
const initialState = () => ({
	loading: false,
	userForm: {
		open: false,
		user: {},
	},
})

const state = initialState()

const invoiceItemProps = `
	id
	status
	amount
	description
	type
	paid
	paidAt
	createdAt
	refunds {
		status
		amount
		invoiceItemId
		depositId
		stripeId
		createdAt
		refundedAt
	}
`

const getters = {
	orderedProposals: (state) => {
		if (isEmpty(state.userForm.user)) {
			return []
		}

		const proposals = state.userForm.user.proposals.concat(
			...map(
				(child) =>
					map(
						(p) =>
							Object.assign({}, p, {
								fromChild: true,
								childName: `${child.firstName} ${child.lastName}`,
							}),
						child.proposals
					),
				state.userForm.user.children
			)
		)

		return reverse(sortBy((p) => p.lastStatusChangedAt || p.createdAt, proposals))
	},

	orderedUserRequests: (state) => {
		if (isEmpty(state.userForm.user)) {
			return []
		}

		const requests = state.userForm.user.requests.concat(
			...map(
				(child) =>
					map(
						(r) =>
							Object.assign({}, r, {
								fromChild: true,
								childName: `${child.firstName} ${child.lastName}`,
							}),
						child.requests
					),
				state.userForm.user.children
			)
		)

		return reverse(sortBy((p) => p.lastStatusChangedAt || p.createdAt, requests))
	},

	orderedUserSubscriptions: (state) => {
		if (isEmpty(state.userForm.user)) {
			return []
		}

		const subscriptions = state.userForm.user.subscriptions.concat(
			...map(
				(child) =>
					map((s) => {
						return Object.assign({}, s, {
							fromChild: true,
							childName: `${child.firstName} ${child.lastName}`,
						})
					}, child.subscriptions),
				state.userForm.user.children
			)
		)

		return reverse(sortBy((p) => p.updatedAt || p.createdAt, subscriptions))
	},
}

const actions = {
	async syncSubscriptionsWithWow({ commit, dispatch }, { simulation }) {
		commit('setLoading', true)

		try {
			await graphqlClient.mutate({
				eutation: gql`
					mutation SyncSubscriptionsWithWow($simulation: Boolean) {
						syncSubscriptionsWithWow(simulation: $simulation)
					}
				`,
				variables: {
					simulation,
				},
			})

			dispatch('alert/success', 'Sync with WOW finished', { root: true })
		} catch (error) {
			dispatch('alert/error', 'Sync with WOW failed', { root: true })
		} finally {
			commit('setLoading', false)
		}
	},

	async retrySubscription({ commit, dispatch }, { subscriptionId }) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation RetrySubscription($subscriptionId:Int!) {
						retrySubscription(subscriptionId: $subscriptionId) {
							${subscriptionAdminProps}
						}
					}
				`,
				variables: {
					subscriptionId,
				},
			})

			commit('refreshSubscription', response.data.retrySubscription)
			dispatch('alert/success', 'Sync with WOW finished', { root: true })
		} catch (error) {
			dispatch('alert/error', 'Sync with WOW failed', { root: true })
		} finally {
			commit('setLoading', false)
		}
	},

	async cancelSubscription({ commit, dispatch }, { subscriptionId }) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation cancelSubscription($subscriptionId: Int!) {
						cancelSubscription(subscriptionId: $subscriptionId) {
							${subscriptionAdminProps}
						}
					}
				`,
				variables: {
					subscriptionId,
				},
			})

			// commit('setCurrentSubscription', response.data.cancelSubscription)
			commit('refreshSubscription', response.data.cancelSubscription)
			dispatch('alert/success', `Subscription will be cancelled at the end of it's period`, {
				root: true,
			})
		} catch (error) {
			dispatch('alert/error', error.message, { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},

	async undoCancelRequestSubscription({ commit, dispatch }, { subscriptionId }) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation undoCancelRequestSubscriptionForUser($subscriptionId: Int!) {
						undoCancelRequestSubscriptionForUser(subscriptionId: $subscriptionId) {
							${subscriptionAdminProps}
						}
					}
				`,
				variables: {
					subscriptionId,
				},
			})

			// commit('setCurrentSubscription', response.data.cancelSubscription)
			commit('refreshSubscription', response.data.undoCancelRequestSubscriptionForUser)
			dispatch('alert/success', `Subscription cancel request removed`, {
				root: true,
			})
		} catch (error) {
			dispatch('alert/error', error.message, { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},

	async cancelNowSubscription({ commit, dispatch }, { subscriptionId }) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation cancelNowSubscription($subscriptionId:Int!) {
						cancelNowSubscription(subscriptionId: $subscriptionId) {
							${subscriptionAdminProps}
						}
					}
				`,
				variables: {
					subscriptionId,
				},
			})

			const nbrUnusedMonths = response.data.cancelNowSubscription.remainingMonths

			commit('refreshSubscription', response.data.cancelNowSubscription)
			dispatch(
				'alert/success',
				`Subscription has successfully been canceled. You should consider a refund for ${nbrUnusedMonths} months`,
				{ root: true }
			)
		} catch (error) {
			dispatch('alert/error', error.message, { root: true })
		} finally {
			commit('setLoading', false)
		}
	},

	async sendResetLink({ commit, dispatch }, { userId }) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation sendPasswordResetLink($userId: Int!) {
						sendPasswordResetLink(userId: $userId)
					}
				`,
				variables: {
					userId,
				},
			})

			commit('sendResetLink', response.data.sendPasswordResetLink)
			dispatch('alert/success', 'Reset link sent', { root: true })
		} catch (error) {
			dispatch('alert/error', 'Reset link sent', { root: true })
		} finally {
			commit('setLoading', false)
		}
	},

	// OBSOLETE - manual update deposit no longer in use
	async updateDeposit({ commit, dispatch }, sub) {
		commit('setLoading', true)

		// const address = omit(['__typename'], Object.assign({}, payload))
		logger.debug('updatig depo of sub', sub)

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation updateDeposit($props: DepositProps!, $subscriptionId: Int!) {
						updateDeposit(props: $props, subscriptionId: $subscriptionId) {
							id
							price
							reimbursedAt
							incomedAt
							status
							paymentMethod
						}
					}
				`,
				variables: {
					props: { ...omit(['__typename'], Object.assign({}, sub.deposit)) },
					subscriptionId: sub.id,
				},
			})

			if (response.data.updateDeposit) {
				commit('refreshDeposit', { sub, deposit: response.data.updateDeposit })

				// commit('updateDepositForSub', { subscription, userId: state.userForm.user.id })
				dispatch('alert/success', i18n.t('flashMessage.depositUpdate'), { root: true })
			} else {
				dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })
			}
		} catch (error) {
			dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},

	// Generate Stripe invoice
	async addChargeForSubscription({ commit, dispatch }, { sub, months }) {
		// { subId, type, amount }
		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation createChargeForSubscription($subscriptionId: Int!, $months: Int!) {
						createChargeForSubscription(subscriptionId: $subscriptionId, months: $months)
						{
							${invoiceItemProps}
						}
					}
				`,
				variables: {
					subscriptionId: sub.id,
					months,
				},
			})

			if (response.data.createChargeForSubscription) {
				commit('addChargeForSubscription', {
					sub,
					charge: response.data.createChargeForSubscription,
				}) // + deposit (response)

				dispatch('alert/success', i18n.t('flashMessage.invoiceCreated'), { root: true })
			} else {
				dispatch('alert/error', i18n.t('error.invoiceFailed'), { root: true })
			}
		} catch (error) {
			dispatch('alert/error', i18n.t('error.invoiceFailed'), { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},

	// Generate Stripe invoice
	async addKeyForSubscription({ commit, dispatch }, { sub, amount }) {
		// { subId, type, amount }
		commit('setLoading', true)

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation createKeyForSubscription($depositId: Int, $subscriptionId: Int!, $amount: Int!) {
						createKeyForSubscription(depositId: $depositId, subscriptionId: $subscriptionId, amount: $amount)
						{
							${invoiceItemProps}
						}
					}
				`,
				variables: {
					depositId: sub.deposits[0] ? sub.deposits[0].id : null,
					subscriptionId: sub.id,
					amount,
				},
			})

			if (response.data.createKeyForSubscription) {
				commit('createKeyForSubscription', { sub, charge: response.data.createKeyForSubscription }) // + deposit (response)

				dispatch('alert/success', i18n.t('flashMessage.invoiceCreated'), { root: true })
			} else {
				dispatch('alert/error', i18n.t('error.invoiceFailed'), { root: true })
			}
		} catch (error) {
			dispatch('alert/error', i18n.t('error.invoiceFailed'), { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},

	// Attempt Stripe refund
	async refundForSubscription({ commit, dispatch }, { sub, months, manual, description }) {
		// { subId, months }
		commit('setLoading', false)

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation refundSubscription($subscriptionId: Int!, $months: Int!, $manual: Boolean!, $description: String) {
						refundSubscription(subscriptionId: $subscriptionId, months: $months, manual: $manual, description: $description) {
							${subscriptionAdminProps}
						}
					}
				`,
				variables: {
					subscriptionId: sub.id,
					months,
					manual: manual || false,
					description,
				},
			})

			if (response.data.refundSubscription) {
				commit('refundForSubscription', { sub: response.data.refundSubscription }) // + deposit (response)

				dispatch('alert/success', i18n.t('flashMessage.refundDone'), { root: true })
			} else {
				dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })
			}
		} catch (error) {
			dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},

	// Attempt Stripe refund
	async refundDeposit({ commit, dispatch }, { sub, depositId, manual }) {
		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation refundDeposit($depositId: Int!, $subscriptionId: Int!, $manual: Boolean!) {
						refundDeposit(depositId: $depositId, subscriptionId: $subscriptionId, manual: $manual) {
							id
							price
							reimbursedAt
							incomedAt
							status
							paymentMethod
						}
					}
				`,
				variables: {
					depositId,
					subscriptionId: sub.id,
					manual: manual || false,
				},
			})

			if (response.data.refundDeposit) {
				commit('refreshDeposit', { sub, deposit: response.data.refundDeposit })

				dispatch('alert/success', i18n.t('flashMessage.refundDone'), { root: true })
			} else {
				dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })
			}
		} catch (error) {
			dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},

	// Attempt Stripe refund
	async refundNewKey({ commit, dispatch }, { subId, itemId, manual }) {
		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation refundNewKey($itemId: Int!, $manual: Boolean!) {
						refundNewKey(itemId: $itemId, manual: $manual) {
							${invoiceItemProps}
						}
					}
				`,
				variables: {
					itemId,
					manual: manual || false,
				},
			})

			if (response.data.refundNewKey) {
				commit('refreshItem', { subId, item: response.data.refundNewKey })

				dispatch('alert/success', i18n.t('flashMessage.refundDone'), { root: true })
			} else {
				dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })
			}
		} catch (error) {
			dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},

	async createDeposit({ commit, dispatch }, sub) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation createDeposit($subscriptionId: Int!) {
						createDeposit(subscriptionId: $subscriptionId) {
							id
							price
							reimbursedAt
							incomedAt
							status
							paymentMethod
						}
					}
				`,
				variables: {
					subscriptionId: sub.id,
				},
			})

			if (response.data.createDeposit) {
				commit('refreshDeposit', { sub, deposit: response.data.createDeposit })

				dispatch('alert/success', i18n.t('flashMessage.depositUpdate'), { root: true })
			} else {
				dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })
			}
		} catch (error) {
			dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},

	async closeDeposit({ commit, dispatch }, { sub, depositId }) {
		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation closeDeposit($depositId: Int!, $subscriptionId: Int!) {
						closeDeposit(depositId: $depositId, subscriptionId: $subscriptionId) {
							id
							price
							reimbursedAt
							incomedAt
							status
							paymentMethod
						}
					}
				`,
				variables: {
					depositId,
					subscriptionId: sub.id,
				},
			})

			if (response.data.closeDeposit) {
				commit('refreshDeposit', { sub, deposit: response.data.closeDeposit })

				dispatch('alert/success', i18n.t('flashMessage.depositUpdate'), { root: true })
			} else {
				dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })
			}
		} catch (error) {
			dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},
	
	async resendPaymentLink({ commit, dispatch }, { sub }) {
		commit('setLoading', true)
		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation resendPaymentLink($subscriptionId: Int!) {
						resendPaymentLink(subscriptionId: $subscriptionId) 
					}
				`,
				variables: {
					subscriptionId: sub.id,
				},
			})

			if (response.data.resendPaymentLink) {
				dispatch('alert/success', i18n.t('Payment link email sent'), { root: true })
			} else {
				dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })
			}
		} catch (error) {
			dispatch('alert/error', i18n.t(error.message), { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},
	async resendPaymentLinkInvoiceItem({ commit, dispatch }, { itemId }) {
		commit('setLoading', true)
		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation resendPaymentLinkInvoiceItem($itemId: Int!) {
						resendPaymentLinkInvoiceItem(itemId: $itemId) 
					}
				`,
				variables: {
					itemId,
				},
			})

			if (response.data.resendPaymentLinkInvoiceItem) {
				dispatch('alert/success', i18n.t('Payment link email sent'), { root: true })
			} else {
				dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })
			}
		} catch (error) {
			dispatch('alert/error', i18n.t(error.message), { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},
}

const mutations = {
	setLoading(state, status) {
		state.loading = status
	},
	showUserForm(state, user) {
		state.userForm.open = true
		state.userForm.user = Object.assign({}, user)
	},
	resetUserForm(state) {
		state.userForm = initialState().userForm
	},
	refreshSubscription(state, payload) {
		const subIndex = findIndex((sub) => sub.id === payload.id, state.userForm.user.subscriptions)

		if (subIndex >= 0) {
			Vue.set(state.userForm.user.subscriptions, subIndex, payload)
		}
	},
	sendResetLink(state, payload) {
		Vue.set(state.userForm.user, 'resetLink', payload)
	},

	// setUserFormLanguage(state, payload) {
	// 	console.log('setting lang userform', payload)
	// 	Vue.set(state.userForm.user, 'language', payload)
	// },

	addChargeForSubscription(state, { sub, charge }) {
		// const sub = find({ id: sub.id }, state.userForm.user.subscriptions) || find({ id: subId }, flatten(map('subscriptions', state.userForm.user.children)))

		logger.silly('mut: adding charge', sub)

		Vue.set(sub, 'invoiceItems', sub.invoiceItems.concat(charge))

		// sub.invoiceItems.concat(charge)
	},
	createKeyForSubscription(state, { sub, charge }) {
		// const sub = find({ id: subId }, state.userForm.user.subscriptions) || find({ id: subId }, flatten(map('subscriptions', state.userForm.user.children)))

		logger.silly('mut: adding key charge', sub)

		Vue.set(sub, 'invoiceItems', sub.invoiceItems.concat(charge))
	},

	refundForSubscription(state, { sub }) {
		const subIndex = findIndex((s) => s.id === sub.id, state.userForm.user.subscriptions)

		logger.silly('mut: adding refund', subIndex)

		// TODO: support for updating state correctly in case of child subscription refund
		if (subIndex >= 0) {
			Vue.set(state.userForm.user.subscriptions, subIndex, sub)
		}

		// const oldSub = find({ id: sub.id }, state.userForm.user.subscriptions) || find({ id: sub.id }, flatten(map('subscriptions', state.userForm.user.children)))
		// Vue.set(sub, 'manualRefunds', sub.manualRefunds.concat(refund))
	},

	refreshDeposit(state, { sub, deposit }) {
		const itemIdx = findIndex((d) => d.id === deposit.id, sub.deposits)

		if (itemIdx >= 0) {
			Vue.set(sub.deposits, itemIdx, deposit)
		} else {
			const newDepos = sub.deposits || []

			newDepos.push(deposit)
			Vue.set(sub, 'deposits', newDepos)
		}
	},

	refreshItem(state, { subId, item }) {
		const sub =
			find({ id: subId }, state.userForm.user.subscriptions) ||
			find({ id: subId }, flatten(map('subscriptions', state.userForm.user.children)))

		// Vue.set(sub, 'invoiceItems', Object.assign([], sub.invoiceItems))
		const itemIdx = findIndex((i) => i.id === item.id, sub.invoiceItems)

		Vue.set(sub.invoiceItems, itemIdx, item)
	},

	initializeDeposit(state, subId) {
		const sub =
			find({ id: subId }, state.userForm.user.subscriptions) ||
			find({ id: subId }, flatten(map('subscriptions', state.userForm.user.children)))

		Vue.set(sub, 'deposit', { price: 20, status: 3 })
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
	modules: {
		users,
		attribution,
		parkings,
		exports: exp,
		reports,
		municipality,
		coupons,
		sessions,
	},
}
